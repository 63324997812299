import FundUnicefKrTemplate from '@/page-blocks/about-us/clear/fund/unicef-kr/FundUnicefKrTemplate';
import ReportKorea2016 from '@/page-blocks/about-us/clear/fund/unicef-kr/report.korea_2016';
import { PageProps } from 'gatsby';
import React from 'react';

const FundUnicefKr2016: React.FC<PageProps> = (props) => (
  <FundUnicefKrTemplate year={2016} {...props}>
    <ReportKorea2016 />
  </FundUnicefKrTemplate>
);

export default FundUnicefKr2016;
